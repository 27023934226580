
<template>
    <TopBar @setPage="$emit('setPage', $event)" />



    <div class="main-claim-container">
        <div class="info-container">
            <label class="info-header">Claim your $wMATE</label>
            <label class="info-text">Holders of $CELL or $wCELL can claim at time of snapshot!</label>
        </div>

        <div class="claim-container">

            <label class="claim-header">{{ walletConnected ? claimableText : 'Please connect your wallet' }}</label>
            <w3m-button :style="{ 'margin-top': '10px' }" v-show="!walletConnected" />

            <CustomButtonVue v-show="walletConnected" @click="claim" text="Claim" color="green" />

        </div>



    </div>
</template>

<script>

import TopBar from "../components/TopBar.vue";
import CustomButtonVue from '@/components/CustomButton.vue';

import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi'

import { arbitrum } from 'viem/chains'
import { reconnect, writeContract } from '@wagmi/core'

// 1. Define constants
const projectId = '37e2eaf51f15f99e2cf16fd0267619a2'

// 2. Create wagmiConfig
const metadata = {
    name: 'Web3Modal',
    description: 'Web3Modal Example',
    url: 'http://localhost:8080', // origin must match your domain & subdomain
    icons: ['https://avatars.githubusercontent.com/u/37784886']
}

const chains = [arbitrum]
const config = defaultWagmiConfig({
    chains, // required
    projectId, // required
    metadata, // required
    enableWalletConnect: true, // Optional - true by default
    enableInjected: true, // Optional - true by default
    enableEIP6963: true, // Optional - true by default
    enableCoinbase: true // Optional - true by default
})

reconnect(config)
// 3. Create modal
createWeb3Modal({
    wagmiConfig: config,
    projectId,
    enableAnalytics: true // Optional - defaults to your Cloud configuration
})



import { readContract } from '@wagmi/core'
import ClaimABI from "../data/ClaimABI.json";
import { getAccount } from '@wagmi/core'
import { ethers } from 'ethers'




export default {
    name: 'ClaimPage',
    components: {
        TopBar,
        CustomButtonVue
    },
    data() {
        return {
            walletConnected: false,
            checker: null,
            claimable: 0
        }
    },
    computed: {
        claimableText() {
            if (this.claimable == 0) return 'You have no $wMATE to claim'

            return `You have ${this.claimable} $wMATE to claim`
        }
    },
    methods: {
        async claim() {
            try {
                const account = getAccount(config)

                if (account.status == 'disconnected') {
                    this.walletConnected = false

                    clearInterval(this.checker)

                    this.checker = setInterval(() => {
                        if (this.walletConnected) clearInterval(this.checker);

                        this.getData();
                    }, 500)
                    return;
                } else {
                    this.walletConnected = true
                }

                await writeContract(config, {
                    abi: ClaimABI,
                    address: "0xBfbE56e9D7413c5fdAB990E60f26060bF1a344ab",
                    functionName: 'claim'
                })

                this.getData();

            } catch (e) {
                console.log(e)
            }
        },
        async getData() {
            try {

                const account = getAccount(config)

                if (account.status == 'disconnected') {
                    this.walletConnected = false
                    clearInterval(this.checker)

                    this.checker = setInterval(async () => {
                        if (this.walletConnected) clearInterval(this.checker);

                        await this.getData();
                    }, 500)
                    return;
                } else {
                    this.walletConnected = true
                }

                const d = await readContract(config, {
                    abi: ClaimABI,
                    address: "0xBfbE56e9D7413c5fdAB990E60f26060bF1a344ab",
                    functionName: 'claimable',
                    args: [account.address]
                })

                let formatted = ethers.utils.formatUnits(d, 18);

                this.claimable = formatted
                console.log(formatted)
            } catch (e) {
                console.log(e)
            }

        }
    },
    mounted() {
        this.getData()

        this.checker = setInterval(async () => {
            if (this.walletConnected) clearInterval(this.checker);

            await this.getData();
        }, 500)
    },
    unmounted() {
        clearInterval(this.checker)
    }
}

</script>

<style scoped>
.info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin-top: 50px;
}

.claim-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin-top: 50px;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}

.claim-header {
    font-size: 15px;
    margin-top: 10px
}

.info-header {
    font-size: 20px;
    margin-bottom: 10px;
}

.info-text {
    font-size: 10px;
    margin-bottom: 10px;
}


.main-claim-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    margin-top: 50px;
}
</style>