<template>


  <HomePage @setPage="setPage" v-if="page == 'HomePage'"/>
  <DetailsPage @setPage="setPage" v-else-if="page == 'DetailsPage'"/>
  <WrapPage @setPage="setPage" v-else-if="page == 'WrapPage'"/>
  <ClaimPage @setPage="setPage" v-else-if="page == 'ClaimPage'"/>
  <StakingPage @setPage="setPage" v-else-if="page == 'StakingPage'"/>

</template>

<script>
import HomePage from "./pages/HomePage.vue";
import DetailsPage from "./pages/DetailsPage.vue";
import WrapPage from "./pages/WrapPage.vue";
import ClaimPage from "./pages/ClaimPage.vue";
import StakingPage from "./pages/StakingPage.vue";


export default {
  name: 'App',
  components: {
    HomePage,
    DetailsPage,
    WrapPage,
    ClaimPage,
    StakingPage
  },
  data() {
    return {
      page: 'HomePage'
    }
  },
  methods: {
    setPage(page) {
      this.page = page
    }
  }
}
</script>

<style>

@font-face {
  font-family: PixelFont;
  src: url("./assets/fonts/PixelFont.woff2");
}

#app {
  font-family: PixelFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  margin: 0px;
  background: center #d1d3dc;
}

input[type=number] {
    width: 100%; /* Adjusts the width of the input to fill its container */
    padding: 12px 20px; /* Adds space inside the input */
    margin: 8px 0; /* Adds some space above and below the input */
    display: inline-block; /* Ensures the input does not break into a new line */
    border: 1px solid #ccc; /* Adds a subtle border */
    border-radius: 4px; /* Rounds the corners */
    box-sizing: border-box; /* Ensures padding does not affect the final size */
    background-color: #f8f8f8; /* Light background color */
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); /* Subtle inner shadow for depth */
    transition: border-color 0.2s; /* Smooth transition for focus effect */
}

/* For Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.input-container {
    width: 75%;
    margin-top: 20px;
}

/* For Firefox */
input[type=number] {
    -moz-appearance: textfield;
    text-align: center;
}

input[type=number]:focus {
    border-color: #4CAF50; /* Changes border color to green when focused */
    outline: none; /* Removes the default focus outline */
}
</style>
