
<template>
    <TopBar @setPage="$emit('setPage', $event)" />

    <div class="main-details-container">

        This page is still under construction. Please check back later.
        <CustomButtonVue @click="$emit('setPage', 'HomePage')" text="Go back" color="green" />

    </div>
</template>

<script>

import TopBar from "../components/TopBar.vue";
import CustomButtonVue from '@/components/CustomButton.vue';

export default {
    name: 'DetailsPage',
    components: {
        TopBar,
        CustomButtonVue
    },
    methods: {
        openUrl(url) {
            window.open(url, '_blank');
        }
    }
}

</script>

<style scoped>


.main-details-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    margin-top: 50px;
}


</style>