
<template>
    <TopBar @setPage="$emit('setPage', $event)" />



    <div class="main-stake-container">
        <div class="info-container">
            <label class="info-header">Lock up your $MATE to accumulate $wMate</label>
            <label class="info-text">{{'There is '+ availableToClaimTotal +' $wMate left to claim over 6 weeks'}}</label>
        </div>

        <div v-show="!optionChosen" class="staking-container">

            <label class="claim-header">{{ walletConnected ? 'Do you want to stake or unstake?' : 'Please connect your wallet' }}</label>
            <w3m-button :style="{ 'margin-top': '10px' }" v-show="!walletConnected" />

            <div class="button-container">
                <CustomButtonVue v-show="walletConnected" @click="changePage('stake')" text="Stake" color="green" />
                <CustomButtonVue v-show="walletConnected" @click="changePage('unstake')" text="Unstake/claim" color="red" />
            </div>



        </div>

        <div v-if="optionChosen && page == 'stake'" class="staking-container">

            <CustomButtonVue :style="{'max-width':'100px'}" @click="changePage('back')" text="back" :color="'red'" />

            <label class="claim-header">{{ 'Choose a lock-up period and amount' }}</label>
            <w3m-button :style="{ 'margin-top': '10px' }" v-show="!walletConnected" />

            <label>{{ 'You have ' + availableToStake + ' $MATE available to stake' }}</label>

            <div class="input-container">
                <input v-model="amountToStake" @input="handleInput($event.target.value)" type="number" />
            </div>
            

            <div class="button-container">
                <div class="stake-info-button">
                    <label>1x Multiplier</label>
                    <CustomButtonVue @click="changeStakeOptionChoice(0)" text="1 DAY" :color="stakeOptionChosen == 0 ? 'green' : 'red'" />
                </div>

                <div class="stake-info-button">
                    <label>2x Multiplier</label>
                    <CustomButtonVue @click="changeStakeOptionChoice(1)" text="3 days" :color="stakeOptionChosen == 1 ? 'green' : 'red'" />
                </div>

                <div class="stake-info-button">
                    <label>3x Multiplier</label>
                    <CustomButtonVue @click="changeStakeOptionChoice(2)" text="5 days" :color="stakeOptionChosen == 2 ? 'green' : 'red'" />
                </div>

                <div class="stake-info-button">
                    <label>4x Multiplier</label>
                    <CustomButtonVue @click="changeStakeOptionChoice(3)" text="7 days" :color="stakeOptionChosen == 3 ? 'green' : 'red'" />
                </div>

                <div class="stake-info-button">
                    <label>5x Multiplier</label>
                    <CustomButtonVue @click="changeStakeOptionChoice(4)" text="14 days" :color="stakeOptionChosen == 4 ? 'green' : 'red'" />
                </div>

            </div>

            <div class="estimate-container">
                <label class="estimate-text"> {{ estimateText }}</label>
                <label class="estimate-text"> You can keep your stake open as long as you want and the multiplier will continue</label>
            </div>


            <div class="button-container">
                <CustomButtonVue @click="tryStake" text="stake" color='green' />
            </div>

        </div>


        <div v-if="optionChosen && page == 'unstake'" class="staking-container">

            <CustomButtonVue :style="{'max-width':'100px'}" @click="changePage('back')" text="back" :color="'red'" />

            <div class="staking-info-c">

                <div class="user-claimable">
                    <label>{{ 'You have '+ availableForUserToClaim +' $wMate available to claim!' }}</label>

                    <div class="button-container">
                        <CustomButtonVue @click="claim" text="Claim" color='green' />
                    </div>
                </div>

            </div>

            <div class="staking-info-c">
                <label class="claim-header">{{ 'Your current multiplier is x' }}</label>
            </div>

            <div class="stake-data-container">
                <div class="stake-table">
                    <!-- Table Header -->
                    <div class="stake-table-header">
                        <div class="stake-table-cell">Staked</div>
                        <div class="stake-table-cell">Unlocks In</div>
                        <div class="stake-table-cell">Multiplier</div>
                        <div class="stake-table-cell">Actions</div> <!-- Header for the button column -->
                    </div>
                    <!-- Table Rows -->
                    <div class="stake-table-row" v-for="(stake, index) in stakeData" :key="index">
                        <div class="stake-table-cell">{{ stake.amount + ' $MATE' }}</div>
                        <div class="stake-table-cell">{{ unlocksAtToDigital(stake.unlocksAt) }}</div>
                        <div class="stake-table-cell">{{ stake.multiplier + 'x' }}</div>
                        <div class="stake-table-cell">
                            <!-- Withdraw Button -->
                            <div @click="tryWithdraw(stake.nonce, stake.unlocksAt)" class="withdraw-button">Withdraw</div>
                        </div>
                    </div>
                </div>
            </div>



            </div>



    </div>
</template>

<script>

import TopBar from "../components/TopBar.vue";
import CustomButtonVue from '@/components/CustomButton.vue';

import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi'

import { arbitrum } from 'viem/chains'
import { reconnect, writeContract } from '@wagmi/core'

// 1. Define constants
const projectId = '37e2eaf51f15f99e2cf16fd0267619a2'

// 2. Create wagmiConfig
const metadata = {
    name: 'Web3Modal',
    description: 'Web3Modal Example',
    url: 'http://localhost:8080', // origin must match your domain & subdomain
    icons: ['https://avatars.githubusercontent.com/u/37784886']
}

const chains = [arbitrum]
const config = defaultWagmiConfig({
    chains, // required
    projectId, // required
    metadata, // required
    enableWalletConnect: true, // Optional - true by default
    enableInjected: true, // Optional - true by default
    enableEIP6963: true, // Optional - true by default
    enableCoinbase: true // Optional - true by default
})

reconnect(config)
// 3. Create modal
createWeb3Modal({
    wagmiConfig: config,
    projectId,
    enableAnalytics: true // Optional - defaults to your Cloud configuration
})



import { readContract } from '@wagmi/core'
import { getAccount } from '@wagmi/core'
import { ethers } from 'ethers'

import wArbMatesABI from "../data/wArbMates.json";
import MateStakingABI from "../data/MateStakingABI.json";
import MateRewardsABI from "../data/MateRewardsABI.json";


const ARBCONTRACT = "0x1eA354af25EBC73DE2f520517bE6b2C959623983";

const MateStaking = "0x72F1aD8EBf342b2fd3D8331049b266c007d99aB4";
const MateRewards = "0x13738F86bBdc1D8E485569E03DD326217842EB51";


export default {
    name: 'ClaimPage',
    components: {
        TopBar,
        CustomButtonVue
    },
    data() {
        return {
            walletConnected: false,
            checker: null,
            optionChosen: false,
            page: 'stake',
            availableToStake: 0,
            availableForUserToClaim: 0,
            transactionsGoing: false,
            stakeOptionChosen: 0,
            availableToClaimTotal: 0,
            amountToStake: 1,
            estimatedReward: 0,
            estimateText: "",
            stakeData: [],
        }
    },
    computed: {
        claimableText() {
            if (this.claimable == 0) return 'You have no $wMATE to claim'

            return `You have ${this.claimable} $wMATE to claim`
        },
    },
    methods: {
        isUnlocked(timestamp) {
            return Date.now() / 1000 > timestamp
        },
        unlocksAtToDigital(unlocksAt) {
            const now = Date.now() / 1000; // Current timestamp in seconds
            let delta = unlocksAt - now; // Difference in seconds
            
            if(delta < 0) {
                return "Unlocked"; // Already unlocked
            }

            const days = Math.floor(delta / 86400); // 86400 seconds in a day
            delta -= days * 86400;

            const hours = Math.floor(delta / 3600) % 24; // 3600 seconds in an hour
            delta -= hours * 3600;

            const minutes = Math.floor(delta / 60) % 60; // 60 seconds in a minute
            delta -= minutes * 60;

            const seconds = Math.floor(delta % 60);

            // Pad each time component to ensure two digits
            const paddedDays = days.toString().padStart(2, '0');
            const paddedHours = hours.toString().padStart(2, '0');
            const paddedMinutes = minutes.toString().padStart(2, '0');
            const paddedSeconds = seconds.toString().padStart(2, '0');

            return `${paddedDays}:${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
        },
        changeStakeOptionChoice(choice) {
            this.stakeOptionChosen = choice

            this.updateEstimateText()
        },
        async updateEstimateText() {
            await this.getData()

            this.estimateText = `You will accumulate an estimated ${this.estimatedReward} $wMATE if you stake for 1 week (This will go down as people stake)`

        },
        handleInput(value) {
            this.stakeAmount = value

            this.updateEstimateText()
        },
        changePage(page) {
            if(page == "back") {
                this.optionChosen = false
                return
            }

            if(page == "unstake") {
                this.getClaimData()
            }

            this.optionChosen = true

            this.page = page
        },
        async checkAndHandleApproval() {
            const account = getAccount(config)

            if (account.status == 'disconnected') {
                this.walletConnected = false

                clearInterval(this.checker)

                this.checker = setInterval(() => {
                    if (this.walletConnected) clearInterval(this.checker);

                    this.getData();
                }, 500)
                return;
            } else {
                this.walletConnected = true
            }


            const d = await readContract(config, {
                abi: wArbMatesABI,
                address: ARBCONTRACT,
                functionName: 'allowance',
                args: [account.address, "0x72F1aD8EBf342b2fd3D8331049b266c007d99aB4"]
            })

            let formatted = ethers.utils.formatUnits(d, 18);

            if (formatted == 0) {
                await writeContract(config, {
                    abi: wArbMatesABI,
                    address: ARBCONTRACT,
                    functionName: 'approve',
                    args: ["0x72F1aD8EBf342b2fd3D8331049b266c007d99aB4", ethers.constants.MaxUint256]
                })

                await new Promise(resolve => setTimeout(resolve, 3000))
            }
        },
        async getClaimData() {
            const account = getAccount(config)

            console.log("getting claim data")

            if (account.status == 'disconnected') {
                this.walletConnected = false
                clearInterval(this.checker)

                this.checker = setInterval(() => {
                    if (this.walletConnected) clearInterval(this.checker);

                    this.getData();
                }, 500)
                return;
            } else {
                this.walletConnected = true
            }

            const d = await readContract(config, {
                abi: MateRewardsABI,
                address: "0x13738F86bBdc1D8E485569E03DD326217842EB51",
                functionName: 'claimable',
                args: [account.address]
            })

            let formatted = parseFloat(parseFloat(ethers.utils.formatUnits(d, 18)).toFixed(2));

            console.log("Claimable", formatted);

            this.availableForUserToClaim = formatted


            const d2 = await readContract(config, {
                abi: MateStakingABI,
                address: "0x72F1aD8EBf342b2fd3D8331049b266c007d99aB4",
                functionName: 'stakeNonce',
                args: [account.address]
            })

            let nonce = ethers.BigNumber.from(d2).toNumber();

            const d3 = await readContract(config, {
                abi: MateStakingABI,
                address: "0x72F1aD8EBf342b2fd3D8331049b266c007d99aB4",
                functionName: 'getUserStakes',
                args: [0, nonce, account.address]
            })

            console.log("Nonce", nonce);
        
            console.log(d3);

            this.stakeData.filter((d) => {
                return d.claimed == false
            })

            let nonceCount = 0;

            this.stakeData = d3.map((d) => {
                return {
                    amount: ethers.BigNumber.from(d.amount).toNumber(),
                    unlocksAt: ethers.BigNumber.from(d.unlocksAt).toNumber(),
                    multiplier: d.lockMulti,
                    claimed: d.claimed,
                    nonce: nonceCount++
                }
            })

            this.stakeData.sort((a, b) => {
                return a.unlocksAt - b.unlocksAt
            })
        },
        async tryWithdraw(nonce, unlocksAt) {
            if(!this.isUnlocked(unlocksAt)) {
                alert("This stake is not unlocked yet")
            }

            if(this.transactionsGoing) return;
            try {
                const account = getAccount(config)

                if (account.status == 'disconnected') {
                    this.walletConnected = false

                    clearInterval(this.checker)

                    this.checker = setInterval(() => {
                        if (this.walletConnected) clearInterval(this.checker);

                        this.getData();
                    }, 500)
                    return;
                } else {
                    this.walletConnected = true
                }

                this.transactionsGoing = true;

                await writeContract(config, {
                    abi: MateStakingABI,
                    address: "0x72F1aD8EBf342b2fd3D8331049b266c007d99aB4",
                    functionName: 'withdraw',
                    args: [nonce]
                })

                await new Promise(resolve => setTimeout(resolve, 3000))

                this.getData();
                this.getClaimData();

                this.transactionsGoing = false;

            } catch (e) {
                this.transactionsGoing = false;

                console.log(e)
            }
        },
        async tryStake() {
            if(this.transactionsGoing) return;
            try {
                const account = getAccount(config)

                

                if (account.status == 'disconnected') {
                    this.walletConnected = false

                    clearInterval(this.checker)

                    this.checker = setInterval(async () => {
                        if (this.walletConnected) clearInterval(this.checker);

                        await this.getData();
                    }, 500)
                    return;
                } else {
                    this.walletConnected = true
                }

                this.transactionsGoing = true;
                

                await this.checkAndHandleApproval();

                await writeContract(config, {
                    abi: MateStakingABI,
                    address: "0x72F1aD8EBf342b2fd3D8331049b266c007d99aB4",
                    functionName: 'stake',
                    args: [this.amountToStake, this.stakeOptionChosen]
                })

                await new Promise(resolve => setTimeout(resolve, 3000))

                this.getData();

                this.transactionsGoing = false;



            } catch (e) {
                this.transactionsGoing = false;

                console.log(e)
            }
        },
        async claim() {
            if(this.transactionsGoing) return;
            try {
                const account = getAccount(config)

                if (account.status == 'disconnected') {
                    this.walletConnected = false

                    clearInterval(this.checker)

                    this.checker = setInterval(async () => {
                        if (this.walletConnected) clearInterval(this.checker);

                        await this.getData();
                    }, 500)
                    return;
                } else {
                    this.walletConnected = true
                }

                this.transactionsGoing = true;

                await writeContract(config, {
                    abi: MateStakingABI,
                    address: "0x72F1aD8EBf342b2fd3D8331049b266c007d99aB4",
                    functionName: 'claimRewardTokens'
                })

                await new Promise(resolve => setTimeout(resolve, 3000))

                this.getData();
                this.getClaimData();

                this.transactionsGoing = false;

            } catch (e) {
                this.transactionsGoing = false;

                console.log(e)
            }
        },
        async getData() {
            try {

                const account = getAccount(config)

                if (account.status == 'disconnected') {
                    this.walletConnected = false
                    clearInterval(this.checker)

                    this.checker = setInterval(async () => {
                        if (this.walletConnected) clearInterval(this.checker);

                        await this.getData();
                    }, 500)
                    return;
                } else {
                    this.walletConnected = true
                }

                const d = await readContract(config, {
                    abi: wArbMatesABI,
                    address: ARBCONTRACT,
                    functionName: 'balanceOf',
                    args: [account.address]
                })

                let formatted = ethers.BigNumber.from(d);

                this.availableToStake = formatted
                console.log(formatted)

                let stakingAmount = this.amountToStake;
                let stakingOption = this.stakeOptionChosen;

                let totalVal = stakingAmount * (stakingOption + 1);

                const weekInSeconds = 60 * 60 * 24 * 7;

                console.log({
                    totalVal,
                    weekInSeconds
                })

                const d2 = await readContract(config, {
                    abi: MateRewardsABI,
                    address: MateRewards,
                    functionName: 'estimateRewards',
                    args: [totalVal, weekInSeconds]
                })

                const formattedReward = parseFloat(parseFloat(ethers.utils.formatUnits(d2, 18)).toFixed(2));

                this.estimatedReward = formattedReward

                console.log("estimated reward", formattedReward.toString());

                const d3 = await readContract(config, {
                    abi: wArbMatesABI,
                    address: "0xBb3e3F5Ac53aC4f04CE8ea77Aa249575ec99604b",
                    functionName: 'balanceOf',
                    args: ["0x13738F86bBdc1D8E485569E03DD326217842EB51"]
                })

                let formatted3  = parseFloat(parseFloat(ethers.utils.formatUnits(d3, 18)).toFixed(2));

                this.availableToClaimTotal = formatted3

            } catch (e) {
                console.log(e)
            }

        }
    },
    mounted() {
        this.getData()

        this.updateEstimateText();

        this.checker = setInterval(async () => {
            if (this.walletConnected) clearInterval(this.checker);

            await this.getData();
        }, 500)

        //this.optionChosen = true;
        //this.page = 'unstake'
    },
    unmounted() {
        clearInterval(this.checker)
    }
}

</script>

<style scoped>

.estimate-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
}
.info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin-top: 50px;
}

.info-header {
    font-size: 14px;
    font-weight: 600;
}

.estimate-text {
    font-size: 10px;
    font-weight: 400;
    margin-bottom: 10px;
}

.info-text {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 10px;
}

.claim-header {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
}

.stake-info-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 15px;
    font-size: 7px;
}

.stake-info-button label {
    width: 50%;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
}

.button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.staking-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin-top: 50px;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}

.main-stake-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    margin-top: 50px;
}

.stake-data-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px; /* Adjust the width as per your design */
}

.stake-table {
    display: table;
    width: 100%;
    border-collapse: collapse;
}

.stake-table-header, .stake-table-row {
    display: table-row;
}

.stake-table-cell {
    display: table-cell;
    padding: 2px; /* Reduced padding for compactness */
    border: 1px solid #ddd;
    text-align: center;
    font-size: 0.7rem; /* Smaller font size */
}

.stake-table-header {
    background-color: #f2f2f2;
    font-size: 0.95rem; /* Slightly larger font in headers */
}

.stake-table-row:nth-child(odd) {
    background-color: #f9f9f944;
}

.withdraw-button {
    background-color: #1b7709;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    margin: 3px;
    font-size: 10px;
}

.staking-info-c {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 10px;
    margin-bottom: 20px;
}

</style>