
<template>
    <TopBar @setPage="$emit('setPage', $event)" />

    <div class="main-home-container">

        <div class="top-container">
            <label class="top-text">ArbMates</label>
            <img class="logo" src="../assets/images/arbmate.png" alt="logo" />
            <label class="body-text">An experimental hybrid of ERC-20 & ERC-721</label>
            <label class="body-text">ArbMates are the CellMates of Arbitrum!</label>
        </div>

        <w3m-button :style="{ 'margin-top': '10px' }"/>

        <label :style="{'margin':'10px', 'color':'red'}">{{ stakingText }}</label>

        <div class="buy-info-container">
            <label>When buying $MATE make sure to put a whole number in the mate input for the swap, please use the link below to buy using the old sushiswap ui which supports ethToExactTokens</label>
            <img class="instructions-gif" src="../assets/images/instructions.gif" alt="mate-swap" />
        
        </div>

        <div class="buy-container">
            <CustomButtonVue @click="openUrl('https://app.sushi.com/swap?inputCurrency=ETH&outputCurrency=0x1eA354af25EBC73DE2f520517bE6b2C959623983&chainId=42161')" text="Buy $MATE" color="green" />
            <CustomButtonVue @click="openUrl('https://app.uniswap.org/swap?inputCurrency=ETH&outputCurrency=0xBb3e3F5Ac53aC4f04CE8ea77Aa249575ec99604b')" text="Buy $wMATE" color="green" />
            <CustomButtonVue @click="openUrl('https://opensea.io/collection/arbmates')" text="Buy ArbCell on Opensea" color="blue" />
        </div>

    </div>
</template>

<script>

import TopBar from "../components/TopBar.vue";
import CustomButtonVue from '@/components/CustomButton.vue';

import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi'

import { arbitrum } from 'viem/chains'
import { reconnect } from '@wagmi/core'
import { readContract } from '@wagmi/core'
import { getAccount } from '@wagmi/core'
import wArbMatesABI from "../data/wArbMates.json";
import { ethers } from 'ethers';

// 1. Define constants
const projectId = '37e2eaf51f15f99e2cf16fd0267619a2'

// 2. Create wagmiConfig
const metadata = {
    name: 'Web3Modal',
    description: 'Web3Modal Example',
    url: 'http://localhost:8080', // origin must match your domain & subdomain
    icons: ['https://avatars.githubusercontent.com/u/37784886']
}

const chains = [arbitrum]
const config = defaultWagmiConfig({
    chains, // required
    projectId, // required
    metadata, // required
    enableWalletConnect: true, // Optional - true by default
    enableInjected: true, // Optional - true by default
    enableEIP6963: true, // Optional - true by default
    enableCoinbase: true // Optional - true by default
})

reconnect(config)
// 3. Create modal
createWeb3Modal({
    wagmiConfig: config,
    projectId,
    enableAnalytics: true // Optional - defaults to your Cloud configuration
})

export default {
    name: 'HomePage',
    components: {
        TopBar,
        CustomButtonVue
    },
    data() {
        return {
            amountStaked: 0
        }
    },
    computed: {
        stakingText() {
            const max = 256;
            const percent = parseFloat((this.amountStaked / max) * 100).toFixed(2);

            return `${percent}% of total supply staked!`
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        openUrl(url) {
            window.open(url, '_blank');
        },
        async getData() {
            try {
                const ARBCONTRACT = "0x1eA354af25EBC73DE2f520517bE6b2C959623983";

                const d = await readContract(config, {
                    abi: wArbMatesABI,
                    address: ARBCONTRACT,
                    functionName: 'balanceOf',
                    args: ["0x72F1aD8EBf342b2fd3D8331049b266c007d99aB4"]
                })

                let formatted = ethers.BigNumber.from(d);

                console.log("Staking: " + formatted.toString())

                this.amountStaked = formatted.toNumber();
            } catch (e) {
                console.log(e)
            }

        }
    }
}

</script>

<style scoped>

.instructions-gif {
    width: 250px;
    height: 250px;
    margin-top: 10px;
}

.buy-info-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin-top: 20px;
    flex-direction: column;
}

.buy-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin-top: 50px;
}

.main-home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.logo {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
}

.top-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 10px;
    margin-top: 50px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    flex-direction: column;
    min-width: 300px;
    max-width: 500px;
}

.top-text {
    font-size: 20px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 10px;
}

.body-text {
    font-size: 10px;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 3px;
}

</style>