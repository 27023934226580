<template>
    <div :style="getColors" class="button-container">
        <label :style="{'user-select':'none', 'cursor':'pointer'}">{{ text }}</label>
    </div>
</template>

<script>
export default {
    name: 'CustomButton',
    props: ['text', 'color'],
    computed: {
        getColors() {
            if(this.color == 'green') {
                return {
                    'background-color': 'rgba(0, 128, 0, 0.274)',
                    'color': 'rgba(0, 128, 0, 0.733)'
                }
            }

            if(this.color == 'blue') {
                return {
                    'background-color': 'rgba(0, 0, 128, 0.274)',
                    'color': 'rgba(0, 0, 128, 0.733)'
                }
            }

        return {
                'background-color': 'rgba(128, 0, 0, 0.274)',
                'color': 'rgba(128, 0, 0, 0.733)'
            }
        }
    }
}
</script>

<style scoped>
.button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-size: 7px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 10px;
    border-radius: 2px;
    cursor: pointer;
}

</style>