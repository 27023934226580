
<template>
    <TopBar @setPage="$emit('setPage', $event)" />



    <div class="main-wrap-container">
        <div class="info-container">
            <label :style="{'color':'#a81313'}">Important!</label>
            <label class="info-header">Wrapping your $MATE will send your lowest token id $MATE NFT to the wrapper contract and give you 1 $wMate which is a fractionalized $MATE!</label>
        </div>

        <div class="wrap-container">

            <label v-show="!optionChosen" class="wrap-header">{{ walletConnected ? "Choose an option!" : 'Please connect your wallet' }}</label>
            <w3m-button :style="{ 'margin-top': '10px' }" v-show="!walletConnected" />

            <div class="wrap-buttons" v-show="walletConnected && !optionChosen">
                <CustomButtonVue @click="changePage('wrap')" text="Wrap" color="green" />
                <CustomButtonVue @click="changePage('unwrap')" text="unwrap" color="green" />
            </div>

            <div v-show="walletConnected && optionChosen" class="wrap-unwrap-container">

                <CustomButtonVue @click="back" text="Back" color="green" />
                <label>{{ wrap ? 'How much $MATE do you want to wrap?' : 'How much $wMATE do you want to unwrap?' }}</label>
                <label :style="{'margin-top':'10px'}">{{ 'You have ' + amountAvailable + ' available to ' + (wrap ? 'wrap' : 'unwrap') }}</label>
                
                
                <div class="input-container">
                    <input v-model="inputAmount" @input="handleInput($event.target.value)" type="number" />
                </div>

                <CustomButtonVue @click="doAction" :text="transactionsGoing ? 'Waiting..' : wrap ? 'Wrap' : 'Unwrap'" color="green" />

            </div>

        </div>



    </div>
</template>

<script>

import TopBar from "../components/TopBar.vue";
import CustomButtonVue from '@/components/CustomButton.vue';

import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi'

import { arbitrum } from 'viem/chains'
import { reconnect, writeContract } from '@wagmi/core'

// 1. Define constants
const projectId = '37e2eaf51f15f99e2cf16fd0267619a2'

// 2. Create wagmiConfig
const metadata = {
    name: 'Web3Modal',
    description: 'Web3Modal Example',
    url: 'http://localhost:8080', // origin must match your domain & subdomain
    icons: ['https://avatars.githubusercontent.com/u/37784886']
}

const chains = [arbitrum]
const config = defaultWagmiConfig({
    chains, // required
    projectId, // required
    metadata, // required
    enableWalletConnect: true, // Optional - true by default
    enableInjected: true, // Optional - true by default
    enableEIP6963: true, // Optional - true by default
    enableCoinbase: true // Optional - true by default
})

reconnect(config)
// 3. Create modal
createWeb3Modal({
    wagmiConfig: config,
    projectId,
    enableAnalytics: true // Optional - defaults to your Cloud configuration
})



import { readContract } from '@wagmi/core'
import wArbMatesABI from "../data/wArbMates.json";
import { getAccount } from '@wagmi/core'
import { ethers } from 'ethers'


const wARBCONTRACT = "0xBb3e3F5Ac53aC4f04CE8ea77Aa249575ec99604b";
const ARBCONTRACT = "0x1eA354af25EBC73DE2f520517bE6b2C959623983";

export default {
    name: 'WrapPage',
    components: {
        TopBar,
        CustomButtonVue
    },
    data() {
        return {
            walletConnected: false,
            checker: null,
            optionChosen: false,
            wrap: false,
            amountAvailable: 0,
            inputAmount: 0,
            transactionsGoing: false,
        }
    },
    computed: {
        claimableText() {
            if (this.claimable == 0) return 'You have no $wMATE to claim'

            return `You have ${this.claimable} $wMATE to claim`
        }
    },
    methods: {
        handleInput(newValue) {
            if(newValue > this.amountAvailable) {
                this.inputAmount = this.amountAvailable
            }

            if(newValue < 0) {
                this.inputAmount = 0
            }

        },
        changePage(page) {
            this.wrap = page == 'wrap'

            this.getData();
            this.amountAvailable = 0;

            this.optionChosen = true
        },
        back() {
            this.optionChosen = false
        },
        async checkAndHandleApproval() {
            const account = getAccount(config)

            if (account.status == 'disconnected') {
                this.walletConnected = false

                clearInterval(this.checker)

                this.checker = setInterval(() => {
                    if (this.walletConnected) clearInterval(this.checker);

                    this.getData();
                }, 500)
                return;
            } else {
                this.walletConnected = true
            }

            let contract = "";

                if(this.wrap) {
                    contract = ARBCONTRACT
                } else {
                    contract = wARBCONTRACT
                }

            const d = await readContract(config, {
                abi: wArbMatesABI,
                address: contract,
                functionName: 'allowance',
                args: [account.address, "0xbb3e3f5ac53ac4f04ce8ea77aa249575ec99604b"]
            })

            let formatted = ethers.utils.formatUnits(d, 18);

            if (formatted == 0) {
                await writeContract(config, {
                    abi: wArbMatesABI,
                    address: ARBCONTRACT,
                    functionName: 'approve',
                    args: ["0xbb3e3f5ac53ac4f04ce8ea77aa249575ec99604b", ethers.constants.MaxUint256]
                })

                await new Promise(resolve => setTimeout(resolve, 3000))
            }
        },
        async doAction() {
            if(this.transactionsGoing) return;
            try {
                const account = getAccount(config)

                

                if (account.status == 'disconnected') {
                    this.walletConnected = false

                    clearInterval(this.checker)

                    this.checker = setInterval(async () => {
                        if (this.walletConnected) clearInterval(this.checker);

                        await this.getData();
                    }, 500)
                    return;
                } else {
                    this.walletConnected = true
                }

                this.transactionsGoing = true;
                

                await this.checkAndHandleApproval();

                await writeContract(config, {
                    abi: wArbMatesABI,
                    address: "0xbb3e3f5ac53ac4f04ce8ea77aa249575ec99604b",
                    functionName: this.wrap ? 'wrap' : 'unwrap',
                    args: [this.inputAmount]
                })

                await new Promise(resolve => setTimeout(resolve, 3000))

                this.getData();

                this.transactionsGoing = false;



            } catch (e) {
                this.transactionsGoing = false;

                console.log(e)
            }
        },
        async getData() {
            try {

                const account = getAccount(config)

                console.log("Checking account status")

                console.log(account)

                if (account.status == 'disconnected') {
                    this.walletConnected = false
                    return;
                } else {
                    this.walletConnected = true

                    console.log("Account connected")
                }

                let contract = "";

                if(this.wrap) {
                    contract = ARBCONTRACT
                } else {
                    contract = wARBCONTRACT
                }

                const d = await readContract(config, {
                    abi: wArbMatesABI,
                    address: contract,
                    functionName: 'balanceOf',
                    args: [account.address]
                })

                console.log(d);

                let formatted = ethers.BigNumber.from(d);

                if(!this.wrap) {
                    formatted = ethers.utils.formatUnits(d, 18);
                }

                this.claimable = formatted
                console.log(formatted)

                //round to lowest whole number
                formatted = Math.floor(formatted)

                this.amountAvailable = formatted
            } catch (e) {
                console.log(e)
            }

        }
    },
    async mounted() {
        await this.getData()

        this.checker = setInterval(async () => {
            if (this.walletConnected) clearInterval(this.checker);

            await this.getData();
        }, 500)
    },
    unmounted() {
        clearInterval(this.checker)
    }
}

</script>

<style scoped>

.wrap-unwrap-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
}
.wrap-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin-top: 50px;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}



.wrap-header {
    font-size: 20px;
    margin-bottom: 10px;
}

.wrap-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
}

.main-wrap-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    margin-top: 50px;
}

.info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin-top: 50px;
}
</style>