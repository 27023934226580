<template>

<div class="topbar">

    <div class="left-side">
        <img class="logo" src="../assets/images/arbmate.png" alt="logo" />
        <label :style="{'font-weight':'bold'}">ArbMates</label>
    </div>

    <div class="right-side">
        <label @click="$emit('setPage', 'HomePage')" class="links">Home</label>
        <label @click="$emit('setPage', 'StakingPage')" class="links">Staking</label>
        <label @click="$emit('setPage', 'DetailsPage')" class="links">Details</label>
        <label @click="$emit('setPage', 'WrapPage')" class="links">Wrap</label>
        <label @click="$emit('setPage', 'ClaimPage')" class="links">Claim</label>
    </div>

</div>

</template>

<script>
/* eslint-disable */
import CustomButton from './CustomButton.vue';
import { useWeb3Modal } from '@web3modal/wagmi/vue'



export default {
    name: 'TopBar',
    components: {
        CustomButton
    },
    methods: {
        connect() {
            const { connectWallet } = useWeb3Modal()
            connectWallet()
        }
    }

}


</script>


<style scoped>

.left-side {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 50%;
    height: 100%;
}

.right-side {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
    height: 100%;
}

.links {
    margin-right: 20px;
    font-size: 12px;
    letter-spacing: 1px;
    color: #2c3e50;
    cursor: pointer;
    transition: 0.3s;
}

.links:hover {
    color: #DBA2BF;
    transition: 0.3s;
}

.logo {
    width: 60px;
    height: 60%;
    margin-left: 20px;
    object-fit: contain;
}

.topbar {
    width: 100%;
    height: 50px;
    background-color: #c6c9d4;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.39);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

</style>